.section_indices{
	background-image: url('/jnh/background/bg_footer2.jpg');
	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: $colorPrimary;
}
.footer1{
	border-top: solid 3px $colorPrimary;
	width: 100%;
	display: block;
	background-color: $colorPrimary;
	background-image: url('/jnh/background/bg_footer2.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;

	& .upfooter{
		background-color: white;
		padding: 1rem .5rem;
		width: 100%;
		display: block;

		& .contatofooter{
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			& a{
				padding: 0 1rem;
				display: block;
				border-right: solid 2px transparent;
				transition: all .15s;
				border-radius: .1rem;
				& p{
					display: inline-block;
					vertical-align: middle;
					font-weight: 400;
					color: $colorPrimary;
					font-size: .9rem;
				}
				& i{
					color: $colorPrimary;
					vertical-align: middle;
					transition: all .15s;
					display: inline-block;
				}
				&:hover{
					border-right: solid 2px $colorTerciary;
					padding-right: 1.5rem;
					& i{
						color: $colorTerciary;
					}
				}
			}

			& .tel_footer{
				display: inline-block;
				vertical-align: middle;
				padding: 0 1rem;
				font-weight: 400;
				color: $colorPrimary;
				font-size: .9rem;
				& i{
					font-weight: 500;
					color: $colorPrimary;
					vertical-align: middle;
					display: inline-block;
				}
			}

			& .rs_footer{
				display: block;
				width: 100%;
				& a{
					margin: 0 .5rem;
					display: inline-block;
					vertical-align: middle;
					border-right: none;
					padding: 0;
					
					& p{
						& i{
							color: $colorPrimary;
							background-color: transparent;
							border: solid 1px transparent;
							padding: .5rem;
							transition: all .2s;
						}
					}

					&:hover{
						& p{
							& i{
								border: solid 1px $colorTerciary;
								background-color: $colorPrimary;
								color: $colorTerciary;
							}
						}
						border-right: none;
						padding-right: none;
					}
				}
			}
		}
	}

	& .btn_footer{
		& a{
			border-left: solid 2px transparent;
			border-radius: 0 0 0 .5rem;
			transition: all .25s;
			&:hover{
				padding-left: .8rem;
				border-left: solid 2px white;
			}
		}
	}

	& .section_atendimento_footer{
		display: block;
		width: 100%;
		& h3{
			user-select: none;
			font-size: 1.1rem;
			font-weight: 700;
			color: white;
			padding: 0;
		}
		& .horarios{
			display: block;
			width: 100%;
			& p{
				color: white;
				font-size: .9rem;
				font-weight: 600;
				line-height: 1.5rem;
				user-select: none;
			}

			& .p2{
				user-select: none;
				text-align: left;
				color: white;
				font-size: .9rem;
				font-weight: 400;
				line-height: 1.5rem;
			}
		}
	}

	& .section_endereco_footer{
		display: block;
		width: 100%;

		& h3{
			font-size: 1.1rem;
			font-weight: 700;
			color: white;
			padding: 0;
			user-select: none;
		}

		& .endereco_itens{
			display: block;
			width: 100%;
			& p{
				color: white;
				font-size: .9rem;
				line-height: 1.5rem;
				font-weight: 400;
				user-select: none;
				padding-right: 3rem;
			}
		}
	}

	& .section_imoveis_footer{
		display: block;
		width: 100%;
		& h3{
			text-align: left;
			font-size: 1.1rem;
			font-weight: 700;
			color: white;
			padding: 0;
			user-select: none;
		}

		& .imoveis_itens{
			display: inline-block;
			vertical-align: top;
			width: 100%;
			padding: 0 1rem;
			& a {
				width: fit-content;
				display: block;
				& p{
					color: white;
					font-size: .9rem;
					line-height: 1.5rem;
					font-weight: 400;
					&:hover{
						color: $colorTerciary;
					}
				}
			}
		}

		& .imoveis_itens2{
			display: inline-block;
			vertical-align: top;
			width: 100%;
			padding: 0 1rem;
			& a {
				width: fit-content;
				display: block;
				& p{
					color: white;
					font-size: .9rem;
					line-height: 1.5rem;
					font-weight: 400;
					&:hover{
						color: $colorTerciary;
					}
				}
			}
		}

	}

	& .section_corretores_footer{
		display: block;
		width: 100%;

		& h3{
			text-align: left;
			font-size: 1.1rem;
			font-weight: 700;
			color: white;
			padding: 0;
			user-select: none;
		}

		& .corretor{
			display: block;
			width: 100%;

			& p{
				user-select: none;
				text-align: left;
				color: white;
				font-size: .9rem;
				font-weight: 400;
				line-height: 1.5rem;
			}

			& a{
				width: fit-content;
				display: block;
				& p{
					color: $colorPrimary;
					font-size: .9rem;
					font-weight: 600;
					background-color: $colorTerciary;
					padding: .2rem 1rem;
					text-align: center;
					border-radius: .2rem;
					transition: all .2s ease-in-out;
					&:hover{
						background-color: darken($colorTerciary, 5);
					}
				}
			}
		}
	}


	& .copyright_footer{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& p{
			text-align: left;
			color: white !important;
			font-size: .9rem;
			font-weight: 400;
			& strong{
				color: white;
			}
		}
	}

	& .devby{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& .developed{
			display: inline-block;
			vertical-align: middle;
			width: 50%;

			& p{
				text-align: right;
				color: white;
				font-size: .9rem;
				font-weight: 400;
			}
		}

		& a{
			display: inline-block;
			vertical-align: middle;
			width: 25%;

			& .logo{
				width: 4rem;
				& img{
					width: 100%;
				}
			}

			& .logo2{
				width: 7rem;
				& img{
					width: 100%;
				}
			}
		}
	}
}
.line-hgt{
	line-height: 1.5rem !important;
	& a {
		line-height: 1.5rem !important;
	}
}
.footer-content a, .footer-content a:visited{
	color: #FFF;
	font-weight: normal;
	display: inline-block;
}
.footer-content a:hover, .footer-content a:active{
	text-decoration: underline;
}

.medium, .medium:hover{
	font-size: 1.15em;
}

.small{
	font-size: 0.9em;
}
/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}

@media all and (max-width: 1023px){

	.footer1{
		& .upfooter{
			& .contatofooter{
				display: inline-block;
				vertical-align: middle;
				width: 100%;

				& a{
					padding: 0 1rem;
					display: block;
					border-left: solid 2px transparent;
					border-right: none;
					transition: all .15s;
					border-radius: .2rem;
					text-align: left !important;

					& p{
						display: inline-block;
						vertical-align: middle;
						font-weight: 400;
						color: $colorPrimary;
						font-size: .9rem;
					}
					& i{
						vertical-align: middle;
						color: $colorPrimary;
						display: inline-block;
					}
					&:hover{
						border-left: solid 2px $colorTerciary;
						border-right: none;
						padding-right: 1.5rem;
						& i{
							color: $colorTerciary;
						}
					}
				}
			}
		}

		& .section_imoveis_footer{
			display: block;
			width: 100%;
			& h3{
				text-align: left;
				font-size: 1.1rem;
				font-weight: 700;
				color: white;
				padding: 0;
				user-select: none;
			}
			& .imoveis_itens{
				display: block;
				width: 100%;
				padding: 0;
				& a{
					& p{
						color: white;
						font-size: .9rem;
						line-height: 1.5rem;
						font-weight: 400;
						&:hover{
							color: $gray4;
						}
					}
				}
			}
			& .imoveis_itens2{
				display: block;
				width: 100%;
				padding: 0;
				& p{
					color: white;
					font-size: 1rem;
					line-height: 2rem;
					font-weight: 400;
					&:hover{
						color: $gray4;
					}
				}
			}
		}

		& .copyright_footer{
			& p{
				text-align: center;
				color: $gray3;
				font-size: .9rem;
				font-weight: 500;
			}
		}

		& .devby{
			display: block;
			width: 100%;
			margin-bottom: 5rem;
			& .developed{
				width: 100%;
				display: block;
				& p{
					text-align: center;
				}
			}
			& a{
				display: inline-block;
				vertical-align: middle;
				width: 50%;
				& .logo{
					width: 5rem;
					& img{
						width: 100%;
					}
				}
				& .logo2{
					width: 8rem;
					& img{
						width: 100%;
					}
				}
			}
		}
	}
}