.title_condomi{
	text-align: center;
	& h2{
		font-size: 2rem;
		font-weight: 500;
		color: $colorPrimary;
	}
	& p{
		font-size: 1rem;
		font-weight: 400;
		color: $gray3;
		text-align: center;
	}
	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 3px;
		margin: auto;
		background-color: $colorTerciary;
	}
}

.box_header{
	border-bottom: solid 1px $gray5;
	z-index: 999;
	position: relative;
}

.blog_section{
	width: 100%;
	display: block;
	& h2{
		font-size: 2.3rem;
		font-weight: 800;
		color: $colorTerciary;
	}
	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: 0;
		background-color: $colorPrimary;
	}
}

.section_servicos{
	width: 100%;
	display: block;
	background-color: white;
	text-align: center;

	& .margin_pc{
		margin: 0 4rem;
	}

	& a{
		width: 25%;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		position: relative;

		& .bloco_servico {
			display: block;
			position: relative;
			width: 100%;
			background-color: white;
			border: solid 1px darken($gray5, 3);
			border-radius: .2rem;
			padding: 1rem;
			z-index: 0;
			overflow: hidden;
	
			& .img{
				width: 3rem;
				& img{
					transition: all .3s;
					text-align: center;
					width: 100%;
				}
			}

			& h2{
				color: $colorTerciary;
				font-size: 1.1rem;
				font-weight: 800;
				transition: all .3s;
				text-align: center;
			}

			& p{
				padding: 0;
				color: $gray2;
				font-size: .9rem;
				font-weight: 400;
				transition: all .3s;
				text-align: center;
			}
		
			&::before {
				content: "";
				position: absolute;
				z-index: -1;
				top: -150px;
				right: -40px;
				background: $gray5;
				height: 32px;
				width: 32px;
				border-radius: 32px;
				transform: scale(1);
				transform-origin: 50% 50%;
				transition: transform 0.3s ease-out;
			}
		}

		&:hover{
			& .bloco_servico{

				& .img{
					& img{
						transform: scale(1.1);
					}
				}

				&::before {
					transform: scale(30);
				}
			}
		}
	}
}

.miniatura_rural {
	display: block;
	width: 100%;
	position: relative;
	line-height: 0;

	@include transition('15s all');
	& img {
		filter: brightness(80%);
	}
	
	&:hover, &:focus{

		& img {
			filter: brightness(100%);
		}
	}
	& .texto-rural{
		display: block;
		width: 70%;
		position: absolute;
		bottom: 6rem;
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;
		background-color: rgba($colorTerciary, .9);
		border-radius: .2rem;
		padding: .5rem 1rem;
		font-weight: 600;
		color: $gray1;
		text-align: center;
		font-size: 1.7rem;
		z-index: 1;
	}
}

.bg_busca_interna{
	background-color: white;
}

#ux_to_top{
	position: relative;
	top: -60px;
	left: -60%;
	z-index: 999999;
}

.botao_voltar{
	background-color: $colorTerciary;
	border: none;
	border-radius: .2rem;
	padding: .3rem 1.5rem;
	color: $colorPrimary;
	font-size: 1rem;
	font-weight: 400;
	cursor: pointer;
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.section_servicos{
		width: 100%;
		display: block;
		background-color: white;
		text-align: center;
	
		& .margin_pc{
			margin: 0 4rem;
		}
	
		& a{
			width: 28%;
			display: inline-block;
			vertical-align: top;
			text-align: center;
			position: relative;
		}
	}
}


@media all and (max-width: 1023px){

	.miniatura_rural {
		display: block;
		width: 100%;
		position: relative;
		line-height: 0;
	
		@include transition('15s all');
		& img {
			filter: brightness(80%);
		}
		
		&:hover, &:focus{
	
			& img {
				filter: brightness(100%);
			}
		}
		& .texto-rural{
			display: block;
			width: auto;
			position: absolute;
			bottom: 3rem !important;
			left: 50%;
			transform: translateX(-50%);
			pointer-events: none;
	
			font-weight: 600;
			color: white;
			font-size: 1.7rem;
			z-index: 1;
		}
	}

	.box_header{
		border-bottom: solid 1px $gray5;
		z-index: 999;
		position: relative;
	}

	.title_condomi{
		text-align: center;
		& h2{
			font-size: 2rem;
			font-weight: 500;
			color: $colorPrimary;
		}
		& p{
			font-size: 1rem;
			font-weight: 400;
			color: $gray3;
			text-align: center;
		}
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 3px;
			margin: auto;
			background-color: $colorTerciary
		}
	}

	.section_servicos{
		width: 100%;
		display: block;
		background-color: white;

		& .margin_pc{
			margin: 0;
		}

		& a{
			width: 100%;
			display: block;
			text-align: center;
			position: relative;
	
			& .bloco_servico {
				display: block;
				position: relative;
				width: 100%;
				background-color: white;
				border: solid 1px darken($gray5, 3);
				border-radius: .2rem;
				padding: 1rem;
				z-index: 0;
				overflow: hidden;
		
				& .img{
					width: 3rem;
					& img{
						transition: all .3s;
						text-align: center;
						width: 100%;
					}
				}
	
				& h2{
					color: $colorTerciary;
					font-size: 1.1rem;
					font-weight: 800;
					transition: all .3s;
					text-align: center;
				}
	
				& p{
					padding: 0;
					color: $gray2;
					font-size: .9rem;
					font-weight: 400;
					transition: all .3s;
					text-align: center;
				}
			
				&::before {
					content: "";
					position: absolute;
					z-index: -1;
					top: -150px;
					right: -40px;
					background: $gray5;
					height: 32px;
					width: 32px;
					border-radius: 32px;
					transform: scale(1);
					transform-origin: 50% 50%;
					transition: transform 0.3s ease-out;
				}
			}
	
			&:hover{
				& .bloco_servico{
	
					& .img{
						& img{
							transform: scale(1.1);
						}
					}
	
					&::before {
						transform: scale(30);
					}
				}
			}
		}
	}
	#ux_to_top{
		left: -12%;
		top: -35px;
	}
}