.miniatura-imovel-video{
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2rem;
	& .miniatura-imovel-img-video{
		border-radius: .2rem .2rem 0 0;
		display: block;
		width: 100%;
		overflow: hidden;
		background-color: $gray1;
		position: relative;
		user-select: none;

		& img{
			width: 100%;
			display: block;
		}

		& .miniatura-video-player{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			& i{
				font-size: 4rem;
				color: white;
				font-weight: 500;
			}
		}
	}

	& .miniatura-imovel-detalhe{
		border: solid 1px $gray4;
		border-radius: 0 0 .2rem .2rem;
		padding: 1rem;

		& .miniatura-acao-tipo{
			display: block;
			width: 100%;
			
			& p{
				display: inline-block;
				vertical-align: middle;
				margin: 0;
			}

			& button{
				margin: 0;
				border-radius: .2rem;
			}
		}
	}
}