.bloco_corretoras{
	display: block;
	width: 100%;
	text-align: center;

	& h2{
		text-align: center;
		font-weight: 400;
		font-size: 1.5rem;
		color: $gray1;
		margin-bottom: 2rem;
        line-height: 2rem;
	}

	& .corretor{
		display: inline-block;
		width: 25%;
		vertical-align: top;
		
		& .img{
			width: 100%;
			display: block;
			text-align: center;
			@include border-box;
			padding: 0 4rem;
			& img{
				width: 100%;
				box-shadow: 0 5px 20px rgba($gray2, .2);
			}
		}
		& .descricao{
			display: block;
			width: 100%;
			margin-top: 1rem;
			& p{
				text-align: center;
				font-size: 1.2rem;
			}
		}
	}
}

.descricao_eqp{
	display: block;
	width: 100%;
	& p{
		color: $gray1;
		font-size: 1.2rem;
		line-height: 2rem;
		text-align: center;
		font-weight: 500;
	}
}

@media all and (max-width: 1023px){
	.bloco_corretoras{
		display: block;
		width: 100%;
		& h2{
			text-align: center;
			font-weight: 400;
			font-size: 1.3rem;
			color: $gray1;
			margin-bottom: 2rem;
            line-height: 1.5rem;
		}
		& .corretor{
			display: inline-block;
			width: 50%;
			margin-bottom: 2rem;
			& .img{
				width: 100%;
				display: block;
				text-align: center;
				@include border-box;
				padding: 0 1.5rem;
				& img{
					width: 100%;
				}
			}
			& .descricao{
				display: block;
				width: 100%;
				margin-top: 1rem;
				& p{
					text-align: center;
					font-size: 1rem;
				}
			}
		}

	}
	.descricao_eqp{
		display: block;
		width: 100%;
		& p{
			color: $gray1;
			font-size: 1rem;
			line-height: 2rem;
			text-align: left;
			font-weight: 500;
		}
	}
}