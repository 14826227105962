/* MINIATURA 1 */
.cr {
	width: 150px;
	padding: .2rem 0;
	position: absolute;
	text-align: center;
	color: #f0f0f0;
}

.cr-sticky {
	position: absolute;
}

/* Positions */
.cr-top    { top: 22px; }
.cr-bottom { bottom: 25px; }
.cr-left   { left: -50px; }
.cr-right  { right: -42px; }

/* Rotations */
.cr-top.cr-left,
.cr-bottom.cr-right {
	transform: rotate(-45deg);
}

.cr-top.cr-right,
.cr-bottom.cr-left {
	transform: rotate(45deg);
}
  
/* Colors */
.cr-primary{
	background-color: $colorTerciary;
	font-weight: 600;
	font-size: .9rem;
	color:$colorPrimary;
}

.miniatura-imovel {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2rem;
	& .miniatura-imovel-img{
		box-shadow: 0 0 20px $gray4;
		border-radius: .2rem .2rem 0 0;
		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 60%;
		background-color: $gray1;
		position: relative;
		user-select: none;

		& img{
			display: block;
			width: 100%;
			position: absolute;
			left: 0;
			top: -30%;
		}

		& .miniatura-imv-status{
			top: 2rem;
			left: 0;
			display: block;
			position: absolute;
			background-color: $colorTerciary;
			padding: .3rem .5rem;
			color: $colorPrimary;
			border-top-right-radius: .2rem;
			border-bottom-right-radius: .2rem;
			font-weight: 600;
			font-size: .8rem;
		}

		& .miniatura-cod{
			position: absolute;
			top: .3rem;
			left: 0;
			& p{
				background-color: rgba(white, .8);
				border-radius: 0 .2rem .2rem 0;
				font-size: .8rem;
				font-weight: 600;
				text-align: center;
				color: $colorPrimary;
				padding: .2rem .5rem;
				line-height: normal;
				margin: 0
			}
		}
	}
	
	& .miniatura-imovel-img-video{
		@extend .miniatura-imovel-img;

		padding-top: 70%;
		position: relative;
		
		& img {
			top: 50%;
			object-fit: none;
			object-position: initial !important;
			transform: translate(0,-50%);
			
		}

		& .miniatura-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			font-size: 4rem;
			color: #ffffff;
		}
	}

	& .miniatura-imovel-detalhe{
		box-shadow: 0 5px 10px rgba(black, .2);
		display: block;
		border-left: solid 1px $gray4;
		border-bottom: solid 1px $gray4;
		border-right: solid 1px $gray4;
		border-radius: 0 0 .2rem .2rem;
		width: 100%;
		padding: 1rem;
		background-color: white;
		@include border-box;
		position: relative;

		& .miniatura-titulo{
			display: block;
			width: 100%;
			& p{
				text-transform: capitalize;
				font-size: .9rem;
				font-weight: 400;
				color: $gray2;

				& i{
					color: $colorTerciary;
				}
			}
			& .miniatura-acao-tipo{
				display: inline-block;
				vertical-align: middle;
				width: 100%;
				& p{
					text-transform: initial;
					font-size: 1.1rem;
					font-weight: 600;
					color: $gray1;
				}
			}
		}
		
		& .miniatura-preco{
			display: inline-block;
			vertical-align: middle;
			width: 50%;
			& p{
				width: 100%;
				padding: 0;
				text-align: left;
				font-weight: 400;
				color: $colorPrimary;
				font-size: 1rem;
				background-color: white;
				border-radius: .2rem;
				margin: 0;
			}
		}


		& .miniatura-imovel-btns-icons{
			display: inline-block;
			width: 50%;
			vertical-align: middle;
			overflow: hidden;

			& ul{
				& li{
					color: $colorPrimary;
					font-size: 1rem;
					& span{
						color: darken($colorTerciary, 10);
					}
				}
			}
		}
	}
}

/* SÓ SE APLICA NO PC */
@media all and (min-width: 1024px) {
	.iwidth-100{
		width: 100% !important;
	}
	.iwidth-50{
		width: 50% !important;
	}
	.iwidth-33-3{
		width: 33.333333% !important;
	}
	.iwidth-25{
		width: 25% !important;
	}
	.iwidth-20{
		width: 20% !important;
	}
}

.splide__arrow--prev{
	left: -2rem !important;
}

.splide__arrow--next{
	right: -2rem !important;
}

@media all and (max-width: 1023px){
	.splide__arrow--prev{
		left: 1em !important;
	}
	
	.splide__arrow--next{
		right: 1em !important;
	}

	.miniatura-imovel {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 2rem;
		& .miniatura-imovel-img{
			box-shadow: inherit;
		}
	
		& .miniatura-imovel-detalhe{
			box-shadow: inherit;
		}
	}
}

@media all and (min-width: 1201px) and (max-width: 1366px){
	.splide__arrow--prev{
		left: 2rem !important;
	}
	
	.splide__arrow--next{
		right: 2rem !important;
	}
}

@media all and (min-width: 1024px) and (max-width: 1200px){
	.splide__arrow--prev{
		left: 1rem !important;
	}
	
	.splide__arrow--next{
		right: 1rem !important;
	}
}

