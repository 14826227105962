.galeriaa_left{

}

.imo_title_interna{
	display: block;
	width: 100%;

	& .title{
		font-size: 1.5rem;
		color: $colorPrimary;
		font-weight: 600;
	}

	& p{
		font-size: 1rem;
		color: $gray2;
		font-weight: 400;
	}
}

.galeriaa_right{
	padding: .5rem 1rem;
	width: 100%;
	border-left: solid 2px $colorTerciary;
	
	& .tarja_valor{
		font-size: 2rem;
		color: $colorPrimary;
		font-weight: 800;
	}

	& .valor_cond{
		display: block;
		width: 100%;

		& p{
			color: $gray2;
			font-size: 1rem;
			& i{
				color: $gray2;
				font-weight: 600;
				cursor: pointer;
			}
		}
	}
}

.formulario_interno{
	background-color: white;
	padding: .5rem 1.5rem;
	border-radius: .2rem;
	border: solid 1px $gray4;

	& h4{
		font-size: 1.5rem;
		font-weight: 800;
		color: $colorPrimary;
		text-align: center;
	}

	& .btn_zap_interno{
		width: 80%;
		display: block;
		background-color: #25d366;
		margin: auto;
		border-radius: 2rem;
		box-shadow: 0 0 0 0 rgba(#25d366, .5);
		-webkit-animation: pulse 1.5s infinite;
		& p{
			text-align: center;
			color: white;
			font-size: 1.2rem;
			font-weight: 800;
			padding: .5rem .2rem;
			vertical-align: middle;

			& i{
				font-weight: 400;
				font-size: 1.5rem;
				vertical-align: middle;
			}
		}
		& .pulse-button:hover {
			-webkit-animation: none;
		}


		@-webkit-keyframes pulse {
			0% {
				@include transform(scale(.9));
			}
			70% {
				@include transform(scale(1));
			box-shadow: 0 0 0 20px rgba(#25d366, 0);
			}
			100% {
				@include transform(scale(.9));
				box-shadow: 0 0 0 0 rgba(#25d366, 0);
			}
		}
	}
}

.texto_descricao_imo{
	max-width: 55rem;
	margin-left: 0;
	line-height: 1.3rem;
	font-size: 1rem;
}

.width_pc_mobile{
	width: 55% !important;
}

.fav_button{
	text-align: center;
	margin: 0;
	width: 40%;
	display: inline-block;
	vertical-align: middle;
	background-color: white;
	border-radius: .2rem 0 0 .2rem;
	border: solid 1px $gray4;
	padding: .2rem 0;
	& p{
		margin: 0;
		text-align: left;
		vertical-align: middle;
		color: $gray3 !important;
		font-size: 1rem;
		font-weight: 500;
	}
	
}

.share_session{
	background-color: white;
	border-radius: 0 .2rem .2rem 0;
	border-top: solid 1px $gray4;
	border-right: solid 1px $gray4;
	border-bottom: solid 1px $gray4;
	display: inline-block;
	vertical-align: middle;
	width: 60%;
	padding: 0;
	
	& .item{
		display: block;
		text-align: center;
		width: 100%;

		& p{
			padding: 0;
			display: inline-block;
			color: $gray2;
			font-size: .9rem;
			font-weight: 400;
		}

		& button{
			margin: 0 .5rem;
		}
	}
}

.imprimir_session{
	background-color: white;
	border: solid 1px $gray4;
	display: inline-block;
	vertical-align: middle;
	border-radius: .2rem 0 0 .2rem;
	width: 40%;
	padding: 0;
	
	& .item{
		display: block;
		text-align: center;
		width: 100%;

		& p{
			margin-right: 0.7rem;
			display: inline-block;
			color: $gray2;
			font-size: .9rem;
			font-weight: 400;
		}

		& button{
			margin: 0;
		}
	}
}

.text_descricao_imo{
	width: 100%;
	display: block;

	& h2{
		font-size: 1.1rem;
		font-weight: 600;
		line-height: 1.5rem;
		margin: 0;
	}

	& p{
		font-size: .9rem;
		font-weight: 400;
		color: $gray2;
		margin: 0;
		line-height: 1.5rem;
	}

	& ul{
		& li{
			font-size: .9rem;
			font-weight: 400;
			color: $gray2;
			margin: 0;
			line-height: 1.5rem;
			
			& i{
				font-weight: 600;
			}
		}
	}
}

/* MOBILE */
@media all and (max-width: 1023px){
	.galeriaa_right{
		padding: .5rem 1rem;
		width: 100%;
		border-left: solid 2px $colorTerciary;
		& .tarja_valor{
			font-size: 1.5rem;
			color: $colorPrimary;
			font-weight: 800;
		}
	}

	.formulario_interno{
		background-color: white;
		padding: .5rem 1.5rem;
		border-radius: .2rem;
		border: solid 1px $gray4;
	
		& h4{
			font-size: 1.5rem;
			font-weight: 800;
			color: $colorPrimary;
		}
		
	}
	.text_descricao_imo{
		width: 100%;
		display: block;

		& h2{
			font-size: 1.1rem;
			font-weight: 600;
			margin: 0;
		}

		& p{
			font-size: .9rem;
			font-weight: 400;
			color: $gray2;
			margin: 0;
			line-height: 1.5rem;
		}
	}

	.width_pc_mobile{
		width: 100% !important;
	}

	.share_session{
		background-color: white;
		border-radius: .2rem;
		border: solid 1px $gray4;
		display: inline-block;
		vertical-align: middle;
		width: 60%;
		padding: 0;
		
		& .item{
			display: block;
			text-align: center;
			width: 100%;
	
			& p{
				padding: 0;
				display: inline-block;
				color: $gray2;
				font-size: .9rem;
				font-weight: 400;
			}
	
			& button{
				margin: 0 .5rem;
			}
		}
	}
}