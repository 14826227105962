.header1{
	background-color: rgba($gray1, .4);
	width: 100%;
	display: block;
	z-index: 99999999;
}

.header2{
	background-color: white;
	width: 100%;
	display: block;
	z-index: 99999999;
	box-shadow: 0 0 10px 1px rgba(black, .05);
	& *{
		color: $gray2 !important;
	}
}
	
.menuacao_btn{
	background-color: $colorPrimary !important;
	color: transparent !important;
}

.headerindex {
	height: calc(100vh - 6.4rem);
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
	
	& .container-bg-headerpc{
		display: block;
		position: absolute;
		top:0;
		overflow: hidden;
		z-index: -1;
		height: 100%;
		width: 100%;

		& .overlay_bg_home{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(black, .5);
			z-index: 2;
		}

		& .bg-headerpc{
			display: block;
			height: calc(100vh + 1px);
			min-height: 40rem;
			width: 100%;
			z-index: 2;
			will-change: background-image;
			background-image: url('/jnh/header/bg-header-01.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center top;
			animation-name: bgheaderpc;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			animation-duration: 20s;
			animation-direction: normal;
		}
		
		@include keyframes('bgheaderpc'){
			0% {
				transform: scale(1);
				background-image: url('/jnh/header/bg-header-01.jpg');
			}
			30% {
				transform: scale(1.02);
				background-image: url('/jnh/header/bg-header-01.jpg');
			}
			40% {
				transform: scale(1);
				background-image: url('/jnh/header/bg-header-2.jpg');
			}
			60% {
				transform: scale(1.02);
				background-image: url('/jnh/header/bg-header-2.jpg');
			}
			70% {
				transform: scale(1);
				background-image: url('/jnh/header/bg-header-3.jpg');
			}
			100% {
				transform: scale(1.02);
				background-image: url('/jnh/header/bg-header-3.jpg');
			}
		}
	}
}
.headerinterno {
	height: 5rem;
	z-index: 1;
}
.busca-top{
	width: 100%;
	background-color: $colorPrimary;
	height: 2rem;
}


#sub_menu_lancamentos, #sub_menu_vendas{
	display: none;
	width: 100%;
	position: absolute;
	left: 0;
	top: 2.2rem;
	background-color: $colorPrimary;
	box-shadow: 7px 7px 10px rgba(black, .2);
	border-radius: 0 0 .5rem .5rem;
	& .elementos_sub_menu{
		text-align: left;
		overflow: auto;
		max-height: 30rem;
		& p{
			font-size: 1.1rem !important;
			font-weight: 400;
			background-image: none;
			padding: 1.2rem 1.5rem;
			margin: 0;
			transition: all .15s;
			border-left: solid 3px transparent;
			&:hover{
				background-image: linear-gradient(to left, transparent 30%, rgba($colorSecondary, .5));
				border-left: solid 3px white;
				padding-left: 2rem;
			}
		}
		& .titletipo{
			color: white;
			font-weight: 800;
			line-height: 1.3em;
		}

		& .itemtipo{
			color: white !important;
			line-height: 1.3em;
			&:hover{
				font-weight: 800;
			}
		}
	}
}

.headerpc {
	display: block;
	width: 100%;
	
	& .superior {
		display: block;
		width: 100%;
		color: #ffffff;
		z-index: 3;
		padding: 1.5rem 0;
		& .logo {
			display: inline-block;
			margin-left: 0;
			@include border-box;
			vertical-align: middle;
			width: 13%;

		
			& img {
				display: block;
				width: 100%;
			}
		}

		/* GRUPO HEADER */

		$largura_share: 7;
		$largura_menu: 3;
		$largura_creci: 10;
		$largura_contact: 13;
		$diferenca: 11;

		& .menu {
			text-align: right;
			display: inline-block;
			width: 5%;
			vertical-align: middle;
			& .wpp_header{
				width: 100%;
				display: block;
				& ul{
					flex: 0 0 100%;
					display: flex;
					align-items: center;
					height: 70px;
					margin: 0%;
					padding: 0%;
					list-style: none;
					justify-content: flex-start;
					transition: all .25s ease;
					will-change: height;
					& .item_zap{
						display: inline-block;
						padding: 0 20px;
						& a{
							justify-content: space-between;
							color: $colorTerciary;
							font-weight: 600;
							font-size: 1.1rem;
							& p{
								font-size: 1.5rem;
								color: $colorPrimary;
								font-weight: 800;
							}
							& .p2{
								text-align: right;
								font-size: .9rem;
								color: darken($gray4, 10);
								font-weight: 400;
							}
							& img{
								width: 3rem;
								vertical-align: middle;
								align-items: left;
							}
						}
					}
				}
			}
		}

		& .sessions {
			display: inline-block;
			width: 82%;
			vertical-align: middle;
			
			  
			& .area_header{
				display: flex;
				justify-content: space-around;
				flex-direction: row;
				align-items: center;

				& .itens_header {
					margin: 0;
					display: inline-block;

					& a{
						color: white;
						text-align: center;
					}

					& p{
						color: white;
						text-align: center;
						font-size: .9rem;
						font-weight: 400;
						line-height: none;
						margin: 0;
					}

					& i{
						color: white;
						text-align: center;
						font-size: 1.2rem;
						vertical-align: middle;
					}
				}
			}
			
		}

		& .contact{
			display: inline-block;
			width: #{$largura_contact}rem;
			vertical-align: middle;
			text-align: center;
			& .mg-tx{
				margin-bottom: 1rem !important;
				margin-top: 0.1rem !important;
			}
			& .mg-tx2{
				margin-bottom: 0 !important;
				margin-top: 0 !important;
			}
		}

	}

	& .busca {
		width: 50%;
		text-align: center;
		position: absolute;
		left: 50%;
		top: 45%;
		transform: translate(-50%, 45%);

		& .btns_tipo_busca{
			display: block;
			width: 100%;
			& h1{
				font-size: 2.5rem;
				color: white;
				font-weight: 800;
			}
			& .botoes{
				display: block;
				width: 50%;
				& button{
					background-color: white;
					border: solid 1px $gray5;
					border-radius: 2rem;
					font-size: 1.6rem;
					color: $colorTerciary;
					font-weight: 600;
					padding: .5rem 1.5rem;
					margin: 0;
					cursor: pointer;
					transition: all .15s;
				}
			}
		}

		& .busca_cond{
			width: 100%;
			display: block;
			& .btns_Cond{
				width: 50%;
				display: block;
				margin: auto;
				& a{
					& p{
						text-align: center;
						font-size: 1.3rem;
						font-weight: 400;
						border: solid 1px $gray4;
						border-radius: .2rem;
						padding: 1rem .5rem;
					}
					& .p2{
						background-color: $colorPrimary;
						color: white;
						text-align: center;
						font-size: 1.3rem;
						font-weight: 400;
						border-radius: .2rem;
						padding: 1rem .5rem;
					}
				}
			}
		}

		& .busca-filtro{
			width: 100%;
			display: block;
			& .bloco_busca{
				padding: 0;
				margin: 0;
				text-align: center;
				display: block;
				width: 100%;
				& .btn-home{
					padding: .61rem 0;
					border-radius: 0 .2rem .2rem 0;
					width: 25%;
					display: inline-block;
					vertical-align: middle;
					background-color: $colorTerciary;
					color: $colorPrimary;
					font-size: 1rem;
					font-weight: 800;
					border: none;
					cursor: pointer;
					&:hover{
						background-color: darken($colorTerciary, 5);
					}
					
				}
			}
		}
	}

	& .frase {
		position: absolute;
		width: 50%;
		right: 5%;
		top: 32%;
		text-align: left;

		& span{
			line-height: 3rem !important;
			font-size: 2.3rem;
		}
		& h2 {
			color: #ffffff;
			font-size: 2rem;
		}
	}
}

.header-frases-arte{
	font-family: 'Quick Signature';
	color: lighten(white, 10%);
	text-shadow: 7px 2px 4px rgba(0, 0, 0, 1);
	font-size: 10rem;
	font-weight: 300;
	line-height: 30px;
}


.videoArea {
	z-index: 1;
	display: block;
	position: relative;
	width: 100%;
	height: 100vh;
	background-color: $colorPrimary;
	background-image: url('/jnh/header/bg-header-01.jpg');
	background-size: cover;

/* 	& .videoareaareaoverlay {
		display: block;
		z-index: 3;
		background-color:rgba(black,0.5);
		position: absolute;
		width: 100%;
		height: 100%;
	}
	& .videoareaarea {
		display: block;
		position: absolute;
		width: 100%;
		z-index: 1;
		height: 100%;
		pointer-events: none;
		overflow: hidden;

		& video {
			object-fit: cover;
			width: 100vw;
			z-index: 1;
			height: 100vh;
			display: inline-block;
			vertical-align: baseline;
		}
	} */

	& .container-header-pc{
		width: 100%;
		height: 100%;
	}
}

.header-mob{
	width: 100%;
	display: block;
	& .log{
		display: inline-block;
		vertical-align: middle;
		width: 8rem;
		& img{
			width: 100%;
		}
	}
	& .btn-men{
		display: inline-block;
		vertical-align: middle;
		margin-right: 0;
		text-align: right !important;
		width: calc(100% - 8rem);
	}
}
// @include keyframes('bgheaderpc'){
// 	0% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-01.jpg');
// 	}
// 	30% {
// 		transform: scale(1.03);
// 		background-image: url('/jnh/header/bg-header-01.jpg');
// 	}
// 	40% {
// 		transform: scale(1.03);
// 		background-image: url('/jnh/header/bg-header-02.jpg');
// 	}
// 	60% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-02.jpg');
// 	}
// 	70% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-01.jpg');
// 	}
// 	100% {
// 		transform: scale(1.03);
// 		background-image: url('/jnh/header/bg-header-01.jpg');
// 	}
// }

.bg-headerinterno {
	background-color: darken($colorPrimary, 5);
}

.headermobile-btn{
	position: absolute;
	width: 100%;
	max-width: 280px;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -60%);
}
.container-bg-headermobile{
	display: block;
	position: absolute;
	top: 0;
	overflow: hidden;
	z-index: -1;
	height: 100%;
	width: 100%;
}
.headermobile {
	display: block;
	width: 100%;
	height: calc(100vh - 65px);
	min-height: calc(100vh - 65px);
	position: relative;
	background-color: $colorPrimary !important;
	will-change: background-image;
	background-image: url('/jnh/header/bg-header-01-mobile.jpg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center top;
	animation-name: headermobile;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 30s;
	animation-direction: normal;
}
// @include keyframes('headermobile'){
// 	0% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
// 	}
// 	30% {
// 		transform: scale(1.02);
// 		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
// 	}
// 	40% {
// 		transform: scale(1.02);
// 		background-image: url('/jnh/header/bg-header-02-mobile.jpg');
// 	}
// 	60% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-02-mobile.jpg');
// 	}
// 	70% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
// 	}
// 	100% {
// 		transform: scale(1.02);
// 		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
// 	}
// }

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.headerpc{
		& .superior {
			& .logo {
				width: 13%;
	
				& img {
					display: block;
					width: 100%;
				}
			}

			& .sessions {
				width: 82%;
			}
		}

		& .busca {
			width: 55%;
			text-align: center;
			position: absolute;
			left: 50%;
			top: 35%;
			transform: translate(-50%, 35%);
	
			& .btns_tipo_busca{
				display: block;
				width: 100%;
				& h1{
					font-size: 2rem;
					color: white;
					font-weight: 800;
				}
				& .botoes{
					display: block;
					width: 50%;
					& button{
						background-color: white;
						border: solid 1px $gray5;
						border-radius: 2rem;
						font-size: 1.6rem;
						color: $colorTerciary;
						font-weight: 600;
						padding: .5rem 1.5rem;
						margin: 0;
						cursor: pointer;
						transition: all .15s;
					}
				}
			}
	
			& .busca_cond{
				width: 100%;
				display: block;
				& .btns_Cond{
					width: 50%;
					display: block;
					margin: auto;
					& a{
						& p{
							text-align: center;
							font-size: 1.3rem;
							font-weight: 400;
							border: solid 1px $gray4;
							border-radius: .2rem;
							padding: 1rem .5rem;
						}
						& .p2{
							background-color: $colorPrimary;
							color: white;
							text-align: center;
							font-size: 1.3rem;
							font-weight: 400;
							border-radius: .2rem;
							padding: 1rem .5rem;
						}
					}
				}
			}
	
			& .busca-filtro{
				width: 100%;
				display: block;
				& .bloco_busca{
					padding: 0;
					margin: 0;
					text-align: center;
					display: block;
					width: 100%;
					& .btn-home{
						padding: .65rem 0;
						border-radius: 0 .2rem .2rem 0;
						width: 25%;
						display: inline-block;
						vertical-align: middle;
						background-color: $colorTerciary;
						color: $colorPrimary;
						font-size: 1rem;
						font-weight: 800;
						border: none;
						cursor: pointer;
						&:hover{
							background-color: darken($colorTerciary, 5);
						}
						
					}
				}
			}
		}
	}

	.headerindex{
		height: calc(100vh - 6.3rem);
		display: block;
		width: 100%;
		padding: 0;
		@include border-box;
	}
}

@media all and (max-width: 1023px){
	
	.header1{
		background-color: rgba($gray1, .4);
		width: 100%;
		display: block;
		z-index: 99999999;
	}

	.header2{
		background-color: white;
		width: 100%;
		display: block;
		z-index: 99999999;
		box-shadow: 0 0 10px 1px rgba(black, .05);
		& *{
			color: $colorTerciary !important;
		}
	}

	.headerindex{
		height: calc(100vh - 12rem);
		display: block;
		width: 100%;
		padding: 0;
		@include border-box;

		& .container-bg-headerpc{
			display: block;
			position: absolute;
			top:0;
			overflow: hidden;
			z-index: -1;
			height: 100%;
			width: 100%;
	
			& .overlay_bg_home{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(black, .5);
				z-index: 2;
			}
	
			& .bg-headerpc{
				display: block;
				height: calc(100vh + 1px);
				min-height: 40rem;
				width: 100%;
				z-index: 2;
				will-change: background-image;
				background-image: url('/jnh/header/bg-header-01-mobile.jpg');
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center left 45%;
				/* animation-name: bgheaderpc;
				animation-timing-function: ease-in-out;
				animation-iteration-count: infinite;
				animation-duration: 20s;
				animation-direction: normal; */
			}
			
			/* @include keyframes('bgheaderpc'){
				0% {
					transform: scale(1);
					background-image: url('/jnh/header/bg-header-01-mobile.jpg');
				}
				30% {
					transform: scale(1.02);
					background-image: url('/jnh/header/bg-header-01-mobile.jpg');
				}
				40% {
					transform: scale(1);
					background-image: url('/jnh/header/bg-header-2-mobile.jpg');
				}
				60% {
					transform: scale(1.02);
					background-image: url('/jnh/header/bg-header-2-mobile.jpg');
				}
				70% {
					transform: scale(1);
					background-image: url('/jnh/header/bg-header-3-mobile.jpg');
				}
				100% {
					transform: scale(1.02);
					background-image: url('/jnh/header/bg-header-3-mobile.jpg');
				}
			} */
		}
	}
	.headerpc{
		
		& .busca {
			width: 80%;
			text-align: center;
			position: absolute;
			left: 50%;
			top: 18%;
			transform: translate(-50%, 18%);
	
			& .btns_tipo_busca{
				display: block;
				width: 100%;
				& h1{
					font-size: 2rem;
					color: white;
					font-weight: 800;
				}
				& .botoes{
					display: block;
					width: 50%;
					& button{
						background-color: white;
						border: solid 1px $gray5;
						border-radius: 2rem;
						font-size: 1.6rem;
						color: $colorTerciary;
						font-weight: 600;
						padding: .5rem 1.5rem;
						margin: 0;
						cursor: pointer;
						transition: all .15s;
					}
				}
			}
	
			& .busca_cond{
				width: 100%;
				display: block;
				& .btns_Cond{
					width: 50%;
					display: block;
					margin: auto;
					& a{
						& p{
							text-align: center;
							font-size: 1.3rem;
							font-weight: 400;
							border: solid 1px $gray4;
							border-radius: .2rem;
							padding: 1rem .5rem;
						}
						& .p2{
							background-color: $colorPrimary;
							color: white;
							text-align: center;
							font-size: 1.3rem;
							font-weight: 400;
							border-radius: .2rem;
							padding: 1rem .5rem;
						}
					}
				}
			}
	
			& .busca-filtro{
				width: 100%;
				display: block;
				& .bloco_busca{
					padding: 0;
					margin: 0;
					text-align: center;
					display: block;
					width: 100%;
					& .btn-home{
						padding: .75rem 0;
						border-radius: 0 .2rem .2rem 0;
						width: 60%;
						display: inline-block;
						vertical-align: middle;
						background-color: $colorTerciary;
						color: $colorPrimary;
						font-size: 1rem;
						font-weight: 800;
						border: none;
						cursor: pointer;
						&:hover{
							background-color: darken($colorTerciary, 5);
						}
						
					}
				}
			}
		}
	}
	
	.container-header-pc{
		display: block;
		width: 100%;
		position: initial;
		background-color: initial !important;
		top: initial;
		left: initial;
		transform: initial;
		z-index: 10;
		max-width: 65rem;
	}
	
}
@media all and (max-width: 1366px){
}
